import { css } from '@emotion/react';
import { FrGa } from '~/modules/SDK/FrGa/FrGa';
import React, { memo } from 'react';
export const CapitalAds = memo(function CapitalAds(props) {
    return (<div css={css `
          width: 100%;
          height: 100%;
        `} onClick={() => {
            FrGa.event({
                點擊廣告: {
                    pageName: props.pageName,
                    adCategory: props.adCategory,
                },
            });
        }}>
        <a href='https://activity.capitalfutures.com.tw/AC240701?EmpNo=A97782' target='_blank' rel='noreferrer'>
          <img css={css `
              width: 100%;
              height: 100%;
            `} src='/futuresai/capitalAds/capitalAdsWithButton.jpg' alt='capitalAds'/>
        </a>
      </div>);
});
