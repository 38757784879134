import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
export class OptionsContractsAPIs extends Urlu {
    get request() {
        return super.request.withJwt(fr_me.getJwt());
    }
    /** 取得當前合約與可用合約 */
    async getContracts() {
        return await this.request.axios.get('/options_contracts');
    }
}
