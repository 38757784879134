import createClient from 'openapi-fetch';
/**
 * @example
 *   // 在你的專案中使用
 *   const sinopacCMS = createApi({ baseUrl: 'https://cms.futures-op.com/api/' })
 *
 *   // 之類的操作，並且正常應該要 typing safe
 *   sinopacCMS.get('/v1/announcements')
 */
const createApi = (...options) => {
    return createClient(...options);
};
export { createApi };
