import { css } from '@emotion/react';
import { Button, Dialog, Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { GrCode, GrTemplate, GrUserExpert } from 'react-icons/gr';
import { FaEdit } from 'react-icons/fa';
import { BsInfoSquare } from 'react-icons/bs';
import { AppVersion } from '~/components/AppVersion';
import { FUTURESAI_THEME, globalGreen, globalGrey, globalOrange, WHITE, } from '~/modules/AppLayout/Colors';
import { fontWeight600 } from '~/css/font';
import { useAppLocalStorage } from '~/hooks/useAppLocalStorage';
import { authDialogState } from '~/modules/auth/containers/authDialogStore';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import dayAPI from '~/utils/dayAPI';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { useRouter } from 'next/router';
import { EXPERIENCE } from '~/modules/auth/components/FillDetailFormDIalog';
import EditUserInfoForm from '~/modules/auth/components/EditUserInfoForm';
import { ActivationCodeFrom } from '~/modules/auth/components/ActivationCodeFrom';
import ChangePasswordForm from '~/modules/auth/components/ChangePasswordForm';
import { CapitalAds } from '~/modules/ads/CapitalAds';
const getMatchedSubscription = (subscriptions, agentName, // target name
product) => {
    /**
     * TODO: fix it. This function may match incorrect product name e.g. asiajye, asiajye_os_futures,
     * asiajye_stock
     */
    const bigProduct = agentName.includes('stock') ? 'stock' : 'web';
    for (const subscription of subscriptions) {
        if (subscription.agentName === agentName && subscription.productName === product) {
            return subscription;
        }
    }
    return null;
};
/**
 * 期天主站會員資訊
 *
 * 包含會員專區編輯`EditUserInfoForm.tsx`視窗
 */
export const UserDetails = memo(function UserInfoDialogContent(props) {
    const firebaseMe = useFirebaseMeState.useContainer();
    const meFirebaseState = useMeStore(state => state.meFirebaseState);
    const meUserState = useMeStore(state => state.meUserState);
    const agentName = useMeStore(state => state.agentName);
    const product = useMeStore(state => state.product);
    const meInfoState = useMeStore(state => state.meInfoState);
    const agentConfig = useMeStore(state => state.agentConfig);
    const isDark = agentConfig.theme?.palette.mode === 'dark';
    const [userInfoDialogOpen, setUserInfoDialogOpen] = useState(false);
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
    const [userActivationCodeDialogOpen, setUserActivationCodeDialogOpen] = useState(false);
    let matchedSubscription = null;
    if (meUserState?.subscriptions) {
        matchedSubscription = getMatchedSubscription(meUserState?.subscriptions, agentName, product);
    }
    const productStatus = matchedSubscription?.status;
    const productInfoBoxCss = css `
    text-align: left;
    padding: 0 0 16px;
    margin: 0 0 16px;
    & > * {
      margin: 8px 0;
      width: 180px;
      color: ${isDark ? WHITE : FUTURESAI_THEME};
      letter-spacing: -1px;
    }
    border-bottom: 1px solid ${globalGrey.g500};
  `;
    const userInfoBoxCss = css `
    text-align: left;
    padding: 0;
    & > * {
      margin: 8px 0;
      color: ${isDark ? WHITE : FUTURESAI_THEME};
      width: 180px;
      letter-spacing: -1px;
    }
  `;
    return (<div css={userDetailRootCss}>
      <UserAvatarAsDialogButton />

      <div css={flex.h.crossCenter}>
        {productStatus && (<img css={badgeCss} src={`/futuresai/auth/badge-${productStatus === 'premium' ? 'premium' : 'trial'}.png`}/>)}
        <Typography variant='subtitle1'>
          {meFirebaseState?.name || meFirebaseState?.email?.replace(agentConfig.emailSuffix, '')}
        </Typography>
      </div>
      <div css={css `
          font-size: 0.8em;
        `}>
        {meFirebaseState?.email?.includes(agentConfig.emailSuffix) ? '' : meFirebaseState?.email}
      </div>

      <p css={css `
          font-size: 1rem;
          ${fontWeight600};
        `}>
        會員代碼: {meUserState?.code}
      </p>

      {matchedSubscription ? (<div css={productInfoBoxCss}>
          <p>
            版本：
            <AppVersion />
          </p>
          <p>
            編碼： {matchedSubscription.agentName}/{matchedSubscription.productName}
          </p>
          {matchedSubscription.status ? (<p>到期： {dayAPI(matchedSubscription.expiredAt).format('YYYY/MM/DD HH:mm')}</p>) : null}
        </div>) : null}

      {meInfoState ? (<div css={userInfoBoxCss}>
          <p>姓名：{meInfoState?.name}</p>
          <p>性別：{meInfoState?.gender}</p>
          <p>出生年月日：{dayAPI(meInfoState?.birthday).format('YYYY/MM/DD')}</p>
          <p>手機：{meInfoState?.mobilePhone}</p>
          <p>
            接觸期貨年限：{EXPERIENCE.find(e => e.value === meInfoState?.tradingSeniority)?.key}
          </p>
        </div>) : null}

      <div css={infoEditActionCss}>
        <FaEdit />
        <p onClick={() => setUserInfoDialogOpen(true)}>
          {meInfoState ? '修改個人資料' : '新增個人資料'}
        </p>
      </div>
      {firebaseMe.firebaseMe?.provider === 'password' ? (<div css={infoEditActionCss}>
          <FaEdit />
          <p onClick={() => setChangePasswordDialogOpen(true)}>修改密碼</p>
        </div>) : null}
      <Dialog onClose={() => setUserInfoDialogOpen(false)} open={userInfoDialogOpen}>
        <EditUserInfoForm parentAction={() => setUserInfoDialogOpen(false)}/>
      </Dialog>
      <Dialog onClose={() => setChangePasswordDialogOpen(false)} open={changePasswordDialogOpen}>
        <ChangePasswordForm parentAction={() => {
            setChangePasswordDialogOpen(false);
            authDialogState.open = false;
        }}/>
      </Dialog>

      <div css={infoEditActionCss}>
        <FaEdit />
        <p onClick={() => {
            setUserActivationCodeDialogOpen(true);
        }}>
          輸入優惠碼
        </p>
      </div>
      <Dialog onClose={() => setUserActivationCodeDialogOpen(false)} open={userActivationCodeDialogOpen}>
        <ActivationCodeFrom>
          <CapitalAds pageName='activationCode' adCategory='capitalAdsWithButton'/>
        </ActivationCodeFrom>
      </Dialog>

      <Button size='large' variant='contained' color='secondary' onClick={firebaseMe.acts.logout}>
        登出
      </Button>
    </div>);
});
/** 期天主站「序號登入」使用者的內容資訊 */
export const TrialUserDetails = memo(function TrialUserDetails() {
    const router = useRouter();
    const agentConfig = useMeStore(state => state.agentConfig);
    const isDark = agentConfig.theme?.palette.mode === 'dark';
    const [, , removeLocalTrialCode] = useAppLocalStorage('localTrialCode', '');
    const handleTrialLogout = () => {
        useMeStore.setState({ isValidTrialUser: false });
        authDialogState.open = false;
        removeLocalTrialCode();
        router.reload();
    };
    return (<div css={css `
        ${flex.v.crossCenter}
        padding: 32px;
        text-align: center;

        & > *:not(button) {
          margin-bottom: 16px;
        }
      `}>
      <p css={css `
          font-size: 1rem;
          ${fontWeight600};
        `}>
        ❤️❤️你好，序號體驗者❤️❤️
      </p>

      <Typography variant='subtitle2'>
        <div>產品版本</div>
        <div>
          <BsInfoSquare fill={isDark ? globalGreen.g400 : globalGreen.g700} stroke={isDark ? globalGreen.g400 : globalGreen.g700} css={css `
              height: 16px;
              width: 16px;
              vertical-align: middle;

              & {
                margin-right: 8px;
                transform: translateY(-1px);
              }
            `}/>
          <AppVersion />
        </div>
      </Typography>

      <Button size='large' variant='contained' color='secondary' onClick={handleTrialLogout}>
        退出
      </Button>
    </div>);
});
const infoEditActionCss = css `
  ${flex.h.crossCenter};
  cursor: pointer;
  padding: 0px;
  margin: 16px 0;

  & > * {
    margin: 0 4px;
    letter-spacing: -1px;
  }
  & > p {
    border-bottom: 1px solid transparent;
    color: ${globalOrange.o800};
  }
  &:hover p {
    border-bottom: 1px solid ${globalOrange.o500};
  }
`;
const userDetailRootCss = css `
  ${flex.v.crossCenter}
  padding: 24px;
  text-align: center;
`;
const badgeCss = css `
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;
/** 其他agent的預設會員資訊顯示 */
export const DefaultUserDetails = memo(function DefaultUserDetails(props) {
    const firebaseMe = useFirebaseMeState.useContainer();
    const meFirebaseState = useMeStore(state => state.meFirebaseState);
    const meUserState = useMeStore(state => state.meUserState);
    const agentName = useMeStore(state => state.agentName);
    const product = useMeStore(state => state.product);
    const agentConfig = useMeStore(state => state.agentConfig);
    const isDark = agentConfig.theme?.palette.mode === 'dark';
    let matchedSubscription = null;
    if (meUserState?.subscriptions) {
        matchedSubscription = getMatchedSubscription(meUserState?.subscriptions, agentName, product);
    }
    const productStatus = matchedSubscription?.status;
    const iconCss = css `
    height: 16px;
    width: 16px;
    vertical-align: middle;

    & {
      margin-right: 4px;
      transform: translateY(-1px);
    }

    & > path {
      stroke: ${isDark ? globalGreen.g400 : globalGreen.g700};
    }
  `;
    return (<div css={css `
        ${flex.v.crossCenter}
        padding: 32px;
        text-align: center;
      `}>
      <UserAvatarAsDialogButton />

      <Typography variant='subtitle1'>{meFirebaseState?.name}</Typography>

      <Typography variant='subtitle2'>
        <div>會員代碼</div>
        <div>
          <GrCode css={iconCss}/>
          {meUserState?.code}
        </div>
      </Typography>

      {meFirebaseState?.email && agentConfig?.loginApproachAllow?.password && (<Typography variant='subtitle2'>
          <div>會員帳號</div>
          <div>
            <GrUserExpert css={iconCss}/>
            {meFirebaseState?.email.replace(agentConfig.emailSuffix, '')}
          </div>
        </Typography>)}

      {matchedSubscription && (<>
          <Typography variant='subtitle2'>
            <div>產品編碼</div>
            <div>
              <GrTemplate css={iconCss}/>
              {matchedSubscription.agentName}/{matchedSubscription.productName}
            </div>
          </Typography>
          <Typography variant='subtitle2'>
            <div>到期日期</div>
            <div>
              <GrTemplate css={iconCss}/>
              <span>{dayAPI(matchedSubscription.expiredAt).format('YYYY/MM/DD')}</span>
            </div>
          </Typography>
        </>)}

      <Typography variant='subtitle2'>
        <div>產品版本</div>
        <div>
          <BsInfoSquare fill={isDark ? globalGreen.g400 : globalGreen.g700} stroke={isDark ? globalGreen.g400 : globalGreen.g700} css={css `
              height: 16px;
              width: 16px;
              vertical-align: middle;

              & {
                margin-right: 8px;
                transform: translateY(-1px);
              }
            `}/>
          <AppVersion />
        </div>
      </Typography>

      <Button size='large' variant='contained' color='secondary' onClick={firebaseMe.acts.logout}>
        登出
      </Button>
    </div>);
});
